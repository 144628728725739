const helper = {};

helper.SHIPMENT_STATUS = {
    AT_CUSTOMER_PLACE: "at_customer_place",
    ON_THE_WAY_TO_SOURCE_BRANCH: "on_the_way_to_source_branch",
    ARRRIVED_SOURCE_BRANCH: "arrived_source_branch",
    ON_THE_WAY_TO_DESTINATION_BRANCH: "on_the_way_destination_branch",
    ARRRIVED_DESTINATION_BRANCH: "arrived_destination_branch",
    ON_THE_WAY_TO_DELIVER_CUSTOMER: "on_the_way_to_deliver_customer",
    DELIVERED: "delivered",
    REJECTED: "rejected",
    INFO_CREATED: "shipment_info_created",
    CANCELED: "canceled",
    HOLDED: "holded", //ສຳລັບ tracking status
    FORWARDED:"forwarded",
    RETURN_TO_SOURCE: "return_to_source",
  };
  helper.PICKUP_STATUS = {
    REJECT: "rejected",
    PICKED: "picked",
    PICKUP_ACCEPT: "picking",
    INFO_CREATED: "requesting",
  };
  helper.COD_CLEARING_STATUS = {
    WAITING: "waiting_transfer",
    TRANSFERING: "transfering",
    TRANSFERED: "transfered",
  };
  
  helper.SHIPMENT_TYPE = {
    NORMAL_SHIPMENT: "SHP",
    RETURN_SHIPMENT: "SRJ",
    FORWARD_SHIPMENT: "SFW",
  };
module.exports = helper;