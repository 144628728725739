<template>
  <div v-if="status">
    <span class="tag" :class="shipmentStatuses[status].class">
      {{ shipmentStatuses[status].label }}
    </span>
  </div>
</template>
<script>
import { get } from "vuex-pathify";
export default {
  name: "ShipmentStatus",
  computed: {
    shipmentStatuses: get("shipmentStatuses"),
  },
  props: {
    status: null,
  },
};
</script>