<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">
        {{ title }}
      </h1>

      <div class="columns">
        <div class="column is-2">
          <b-datepicker
            v-model="fromdate"
            placeholder="ຈາກວັນທີ"
            :locale="this.getDateLocale"
            icon="calendar-today"
            @input="selectFromDate"
          >
          </b-datepicker>
        </div>
        <div class="column is-2">
          <b-datepicker
            v-model="todate"
            placeholder="ຫາວັນທີ"
            :locale="this.getDateLocale"
            icon="calendar-today"
            @input="selectToDate"
          >
          </b-datepicker>
        </div>
        <div class="column is-3" v-if="user.role==='ADMIN'">
          <b-select placeholder="ສາຂາ" expanded v-model="branchId">
            <option
                v-for="option in branchs"
                :value="option.id"
                :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </div>
        <div class="column is-2">
          <button class="button" @click="get">
            <b-icon icon="magnify" />
          </button>
        </div>

        <div class="column is-2 has-text-left" v-if="models.length>0">
          <b-button
              @click="exportExcel"
              size="is-medium">
            <b-icon
                icon="file-excel-outline"
                style="margin-right: 0.5em"></b-icon>
            ດາວໂຫລດເປັນ excel
          </b-button>
        </div>
      </div>

      <card-component class="has-table has-mobile-sort-spaced">
        <div class="columns" style="padding: 1em">
          <div class="column title has-text-centered">
            {{ models.length }} ລາຍການ
          </div>
          <div class="column title has-text-centered">
            {{ _.sumBy(models, "amount") | formatnumber }} ກີບ
          </div>
           <div class="column title has-text-centered">
            {{ _.sumBy(models, "amount_thb") | formatnumber }} ບາດ
          </div>
        </div>
        <hr />
        <b-table
          :loading="isLoading"
          :paginated="perPage < models.length"
          :per-page="perPage"
          :striped="true"
          :hoverable="true"
          default-sort="created_date"
          :data="models"
          :current-page.sync="currentPage"
          :pagination-rounded="true"
          :narrowed="true" class="is-narrow is-size-7"
        >
          <b-table-column label="#" v-slot="props">
            {{ props.index+(currentPage>1?(perPage*currentPage-perPage):0)+1 }}
          </b-table-column>
          <b-table-column
            label="ວັນທີ"
            field="created_date"
            sortable
            v-slot="props"
          >
            {{ props.row.created_date | datetime }}
          </b-table-column>
          <b-table-column label="ລະຫັດ" field="code" sortable v-slot="props">
            <router-link :to="'/shipment/' + props.row.code">
              {{ props.row.code }} <b-tag v-if="props.row.is_cod" type="is-warning">COD</b-tag>
            </router-link>
          </b-table-column>
          <b-table-column
            label="ຜູ້ສົ່ງ"
            field="sendername"
            sortable
            v-slot="props"
          >
            {{ props.row.sendername }}
          </b-table-column>
          <b-table-column
            label="ຜູ້ຮັບ"
            field="receivername"
            sortable
            v-slot="props"
          >
            {{ props.row.receivername }}
          </b-table-column>
          <b-table-column
            label="ສາຂາຕົ້ນທາງ"
            field="branch_branchToshipment_from_branch_id.code "
            sortable
            v-slot="props"
          >
            {{ props.row.branch_branchToshipment_from_branch_id.code }}
          </b-table-column>
          <b-table-column
            label="ສາຂາປາຍທາງ"
            field="branch_branchToshipment_to_branch_id.code"
            sortable
            v-slot="props"
          >
            {{ props.row.branch_branchToshipment_to_branch_id.code }}
          </b-table-column>
          <b-table-column
            label="ຈຳນວນເງິນກີບ"
            field="amount"
            sortable
            v-slot="props"
            cell-class="has-text-right"
          >
            {{ props.row.amount | formatnumber }}
          </b-table-column>
           <b-table-column
            label="ຈຳນວນເງິນບາດ"
            field="amount_thb"
            sortable
            v-slot="props"
            cell-class="has-text-right"
          >
            {{ props.row.amount_thb | formatnumber }}
          </b-table-column>
          <b-table-column
            label="ສະຖານະ"
            field="status"
            sortable
            v-slot="props"
            cell-class="has-text-centered"
          >
            <shipment-status :status="props.row.status" />
          </b-table-column>

          <empty-table v-if="models.length == 0" :isLoading="isLoading" />
        </b-table>
      </card-component>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { get } from "vuex-pathify";
import CardComponent from "@/components/CardComponent";
import EmptyTable from "../components/EmptyTable.vue";
import ShipmentStatus from "../components/ShipmentStatus.vue";
import moment from "moment";
import store from "@/store";
import helper from "@/helper";
import XLSX from "xlsx";
//import helper from "@/helper";
export default {
  name: "ReportSendParcelsFee",
  components: { CardComponent, EmptyTable, ShipmentStatus },
  computed: {
    user: get("user"),
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      title: "ລາຍງານ ຄ່າຝາກເຄື່ອງຢູ່ສາຂາ",
      models: [],
      isShowModal: false,
      isLoading: false,
      fromdate: new Date(),
      todate: new Date(),
      branchId: 0,
      branchs: [],
      deliveryMethod: `${helper.SHIPMENT_STATUS.DELIVERED},${helper.SHIPMENT_STATUS.ARRRIVED_DESTINATION_BRANCH}`,
      deliveryOption: [
        {
          text: "ທັງໝົດ",
          id: `${helper.SHIPMENT_STATUS.DELIVERED},${helper.SHIPMENT_STATUS.ARRRIVED_DESTINATION_BRANCH}`,
        },{
          text: "ເຄຶ່ອງຮອດສາຂາ",
          id: helper.SHIPMENT_STATUS.ARRRIVED_DESTINATION_BRANCH,
        },
        {
          text: "ມອບເຄື່ອງໃຫ້ລູກຄ້າແລ້ວ",
          id: helper.SHIPMENT_STATUS.DELIVERED,
        },
      ],
    };
  },
  mounted() {
    this.getBranch();
    this.getUserBranchId();
    this.get();
  },
  watch: {
    models: function () {
      this.isLoading = false;
    },
  },
  methods: {
    getUserBranchId() {
      this.branchId = store.getters.getUserBranchId;
    },
    getBranch() {
      this.branchs = store.getters.getBranch;
    },
    get() {
      this.isLoading = true;
      const fdate = moment(this.fromdate).format("YYYY-MM-DD");
      const tdate = moment(this.todate).format("YYYY-MM-DD");
      axios
        .get(`report/sendparcelsonbranchfee/${fdate}/${tdate}/${this.branchId}`)
        .then((r) => {
          this.models = r;
        });
    },

    view(item) {
      this.$router.push({ path: "/report/shipment/" + item.id });
    },
    selectFromDate(e) {
      console.log(e);
    },
    selectToDate(e) {
      console.log(e);
    },getUserBranchInfo() {
      return store.getters.getUserIdBranchInfo;
    },
    exportExcel() {
      if (this.models.length<1 ) return;
      //Create workBook
      const workBook = XLSX.utils.book_new();
      /* worksheet  data */
      const header =[

        "ວັນທີ່ສ້າງລາຍການ",
        "ລະຫັດເຄື່ອງ",
        "ຊື່ຜູ້ສົ່ງ",
        "ເບີໂທຜູ້ສົ່ງ",
        "ຊື່ຜູ້ຮັບ",
        "ເບີຜູ້ຮັບ",
        "ສາຂາຕົ້ນທາງ",
        "ສາຂາປາຍທາງ",
        "ຈຳນວນເງີນ",
        "ສະຖານະ",
        "ໝາຍເຫດ"
      ];

        const ws_data_sheet = [];
        ws_data_sheet.push(header);
        for (const item of this.models) {
          ws_data_sheet.push([
            moment(item.created_date).format("YYYY-MM-DD HH:mm:ss"),
            item.code
            ,item.sendername
            ,item.sendertel
            ,item.receivername
            ,item.receivertel
            ,item.branch_branchToshipment_from_branch_id.name
            ,item.branch_branchToshipment_to_branch_id.name
            ,item.amount
            ,item.status
            ,item.is_cod?"ຄ່າຝາກເຄື່ອງ COD":null
          ]);
        }
        /* create worksheet */
        const ws = XLSX.utils.aoa_to_sheet(ws_data_sheet);
        /* Add the worksheet to the workbook */
        XLSX.utils.book_append_sheet(workBook, ws, "ຄ່າສົ່ງເຄື່ງ");

      /* Write workbook to file */
      const _date = moment(this.selectedDate).format("YYYY_MM_DD_HHmmss");
      const _ub = this.getUserBranchInfo();
      XLSX.writeFile(workBook, `SEND_PARCELS_DETAIL_${_ub}_FILE_${_date}.xlsx`);
    },

  },
};
</script>